import React, { useEffect, useRef } from "react";
import {
  Dimensions,
  ScrollView,
  Easing,
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import { OrangeButton } from "./OrangeButton";
import { OrangeBorderButton } from "./OrangeBorderButton";
import VerticalSlider from "rn-vertical-slider";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { LinearGradient } from "expo-linear-gradient";
import type { QuestionList } from "./api";
import { fetchQuestions } from "./api";
import { assertStatusValuesInBounds } from "expo-av/build/AV";

export const ActivityQA = (props: {
  linkcode: any;
  linkyear: any;
  nextStep: () => void;
}) => {
  const [currentquestionno, setCurrentquestionno] = React.useState(0);
  const [activequestionare, setActivequestionare] = React.useState(0);
  const [questions, setQuestions] = React.useState<QuestionList>();
  const [day, setDay] = React.useState();
  const [dayno, setDayno] = React.useState();
  const [healthrater, setHealthrater] = React.useState(0);
  const [keydisabled, setKeydisabled] = React.useState(false);
  const [sliderwizardpassed, setSliderwizardpassed] = React.useState(false);

  const opacity = useRef(new Animated.Value(0)).current;

  const dimensions = Dimensions.get("window");
  const screenwidth = dimensions.width;
  function questionIntro(): void {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(opacity, {
        toValue: 1,
        duration: 350,
        delay: 200,
        useNativeDriver: false,
        easing: Easing.ease,
      }).start();
    });
  }

  function questionFadeout(): void {
    Animated.timing(opacity, {
      toValue: 0,
      duration: 0,
      useNativeDriver: true,
    }).start();
  }

  const movetitle = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [2, 0],
  });

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        const resData = await fetchQuestions(props.linkcode, props.linkyear);

        if (resData.result && resData.questions.length > 0) {
          questionIntro();
          // setDayno(resData.dayno);
          setQuestions(
            resData.questions.filter(
              (q) => q.type !== "break" && q.type !== "choices"
            )
          );
          // setDay(resData.day);
          // if (resData.day.answeredquestions) {
          //   for (let i = 0; i < resData.day.answeredquestions; i++) {
          //     global.answers[`${resData.questions[i].id}`] = null;
          //   }
          //   setCurrentquestionno(resData.day.answeredquestions);
          // }
        }
      } catch (err) {
        console.error(err);
      }
    }

    global.answers = new Object();
    global.answerslocal = new Object();

    void fetchData();
  }, []);

  function goback(): void {
    if (currentquestionno > 0) {
      console.log(`goback currentquestipn:${currentquestionno}`);
      const previousanswer =
        global.answers[`${questions[currentquestionno - 1].id}`];

      // if first questin of the questionare then return
      if (
        questions[currentquestionno - 1].questionnaireId !==
        questions[currentquestionno].questionnaireId
      )
        return;

      delete global.answers[`${questions[currentquestionno - 1].id}`];
      delete global.answerslocal[`${questions[currentquestionno - 1].id}`];

      if (previousanswer == 0) {
        delete global.answers[`${questions[currentquestionno - 2].id}`];
        delete global.answerslocal[`${questions[currentquestionno - 2].id}`];
        setCurrentquestionno(currentquestionno - 2);

        console.log("going back1 more");
      } else {
        setCurrentquestionno(currentquestionno - 1);
      }
    }
  }

  async function answer(answerno: number): Promise<void> {
    if (!questions) throw new Error("Invalid question state");

    setKeydisabled(true);

    global.answers[`${questions[currentquestionno].id}`] = answerno;
    global.answerslocal[`${questions[currentquestionno].id}`] = answerno;
    if (currentquestionno < questions.length - 1) {
      questionIntro();

      if (questions[currentquestionno + 1].dontshowforquestion > 0) {
        if (
          global.answers[
          questions[currentquestionno + 1].dontshowforquestion
          ] === questions[currentquestionno + 1].dontshowforanswer
        ) {
          // todo bunu save ediyor mu kontrol et

          global.answers[`${questions[currentquestionno + 1].id}`] = 0;
          global.answerslocal[`${questions[currentquestionno + 1].id}`] = 0;

          setCurrentquestionno(currentquestionno + 2);
          setKeydisabled(false);
          return;
        } else {
          setCurrentquestionno(currentquestionno + 1);
        }
      } else {
        // normal question
        setCurrentquestionno(currentquestionno + 1);
      }
      setKeydisabled(false);
    } else {
      questionFadeout();
      setCurrentquestionno(currentquestionno + 1);
      props.nextStep();
    }
  }

  const insets = useSafeAreaInsets();

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 40,
          paddingLeft: "8%",
          paddingRight: "8%",
          width: "100%",
        }}
      >
        <TouchableOpacity
          style={{}}
          onPress={() => {
            goback();
          }}
        >
          {currentquestionno > 0 && (
            <Image
              source={require("../assets/images/backbutton.png")}
              style={styles.backbutton}
            />
          )}
        </TouchableOpacity>
      </View>

      {questions && (
        <View
          style={{
            flex: 1,
            paddingLeft: 33,
            paddingRight: 33,
            backgroundColor: "#ffffff",
            borderTopStartRadius: 50,
            borderTopEndRadius: 50,
            marginTop: 23,
            maxWidth: 500,
            alignSelf: "center",
          }}
        >
          <Animated.Text style={styles.title3}>
            {" "}
            {currentquestionno} of {questions.length} questions answered{" "}
          </Animated.Text>

          <View style={styles.progressbar}>
            <Animated.Image
              resizeMode={"cover"}
              source={require("../assets/images/progressbg.jpg")}
              style={[
                styles.progressbarinside,
                { width: (currentquestionno / questions.length) * 100 + "%" },
              ]}
            />
          </View>

          <ScrollView
            scrollEnabled={!(questions[currentquestionno].type === "slider")}
            contentContainerStyle={{
              flexGrow: 1,
              width: "100%",
              paddingBottom: 60,
              marginTop: 15,
            }}
          >
            <View
              style={{
                backgroundColor: "#1F2E99",
                width: "100%",
                marginBottom: 1,
                borderTopStartRadius: 20,
                borderTopEndRadius: 20,
                borderBottomEndRadius: 20,
              }}
            >
              {(questions[currentquestionno]?.questionnaireId === 4 ||
                questions[currentquestionno]?.questionnaireId === 5) && (
                  <Text style={styles.title5}>During the past 4 weeks</Text>
                )}
              {questions[currentquestionno]?.questionnaireId === 3 &&
                questions[currentquestionno]?.type !== "slider" && (
                  <Text style={styles.title6}>
                    Please select the ONE box that best describes your health
                    TODAY
                  </Text>
                )}

              <Animated.Text
                style={[
                  styles.title2,
                  { opacity, transform: [{ translateX: movetitle }] },

                  questions[currentquestionno]?.questionnaireId === 3 &&
                  questions[currentquestionno]?.type !== "slider" &&
                  styles.title2a,
                ]}
              >
                {questions[currentquestionno]?.question}
              </Animated.Text>
            </View>
            <Animated.View
              style={{
                opacity: opacity.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 1],
                }),
              }}
            >
              {questions[currentquestionno]?.answers?.map((a) => (
                <OrangeBorderButton
                  disabled={keydisabled}
                  onPress={async () => {
                    await answer(a[1] + 1);
                  }}
                  title={a[0]}
                  width={"100%"}
                  style={[styles.questionbutton, { marginTop: 29 }]}
                />
              ))}
              {questions[currentquestionno]?.type === "yesno" && (
                <>
                  <OrangeBorderButton
                    disabled={keydisabled}
                    onPress={async () => {
                      await answer(2);
                    }}
                    title={"yes"}
                    width={"100%"}
                    style={[styles.questionbutton, { marginTop: 29 }]}
                  />
                  <OrangeBorderButton
                    disabled={keydisabled}
                    onPress={async () => {
                      await answer(1);
                    }}
                    title={"no"}
                    width={"100%"}
                    style={[styles.questionbutton, { marginTop: 29 }]}
                  />
                </>
              )}

              {questions[currentquestionno]?.type === "slider" &&
                !sliderwizardpassed && (
                  <View>
                    <Text
                      style={{
                        color: "#1F2E99",
                        fontSize: 17,
                        lineHeight: 23.8,
                        fontFamily: "Lato",
                        marginTop: 10,
                      }}
                    >
                      On the next screen you will see a scale numbered{" "}
                      <Text style={{ fontFamily: "LatoBold" }}>0 to 100.</Text>
                      {"\n"}
                      {"\n"}
                      <Text style={{ fontWeight: "bold" }}>100</Text> means{" "}
                      <Text style={{ fontFamily: "LatoBold" }}>
                        the best health
                      </Text>{" "}
                      you can imagine.{"\n"}
                      {"\n"}
                      <Text style={{ fontWeight: "bold" }}>0</Text> means{" "}
                      <Text style={{ fontFamily: "LatoBold" }}>
                        the worst health
                      </Text>{" "}
                      you can imagine.{"\n"}
                      {"\n"}
                      Please indicate on the scale how your health is TODAY.
                    </Text>
                  </View>
                )}

              {questions[currentquestionno]?.type === "slider" &&
                sliderwizardpassed && (
                  <View>
                    <Text
                      style={{
                        color: "#798599",
                        fontSize: 16,
                        fontFamily: "Lato",
                        marginTop: 15,
                        marginLeft: 10,
                      }}
                    >
                      Best health you can imagine
                    </Text>

                    <View
                      style={{
                        flexDirection: "row",
                        width: screenwidth - 18.4 * 2,
                        marginTop: 15,
                      }}
                    >
                      <Image
                        pointerEvents="none"
                        source={require("../assets/images/healthchart2.png")}
                        style={styles.healthchart}
                      />

                      <View
                        pointerEvents="none"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 7,
                          zIndex: 100,
                        }}
                      >
                        <LinearGradient
                          pointerEvents="none"
                          colors={["#4c669f", "#2A1F99", "#2A1F99"]}
                          style={[
                            styles.linearGradient,
                            {
                              height: healthrater * 1.6 + 30,
                              top: 160 - healthrater * 1.6,
                            },
                          ]}
                        />
                      </View>
                      {questions[currentquestionno]?.type === "slider" && (
                        <View style={{ position: "absolute", left: 0, top: 7 }}>
                          <VerticalSlider
                            value={healthrater}
                            min={0}
                            max={100}
                            onChange={(value: number) => {
                              console.log("CHANGE", value);
                              setHealthrater(value);
                            }}
                            onComplete={(value: number) => {
                              setHealthrater(value);
                              console.log("COMPLETE", value);
                            }}
                            width={92}
                            height={192}
                            step={1}
                            borderRadius={20}
                            minimumTrackTintColor={"#F5F6FA"}
                            maximumTrackTintColor={"#F5F6FA"}
                            shadowProps={{
                              elevation: 0,
                            }}
                            ballIndicatorTextColor={"white"}
                          />
                        </View>
                      )}

                      {healthrater < 1200 && (
                        <Image
                          pointerEvents="none"
                          source={require("../assets/images/draghand.png")}
                          style={styles.draghand}
                        />
                      )}

                      <View
                        style={{ position: "absolute", top: -34, right: 0 }}
                      >
                        <Text
                          style={{
                            color: "#FE7235",
                            fontSize: 18,
                            width: 100,
                            textAlign: "left",
                            fontFamily: "Lato",
                          }}
                        >
                          YOUR HEALTH TODAY
                        </Text>
                        <Text
                          style={{
                            color: "#1F2E99",
                            fontSize: 36,
                            width: 100,
                            textAlign: "left",
                            fontFamily: "LatoBold",
                          }}
                        >
                          {healthrater}
                        </Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        color: "#798599",
                        fontSize: 16,
                        fontFamily: "Lato",
                        marginTop: 5,
                        marginLeft: 110,
                      }}
                    >
                      Worst health
                    </Text>
                  </View>
                )}
            </Animated.View>
          </ScrollView>

          {questions &&
            questions[currentquestionno]?.type === "slider" &&
            !sliderwizardpassed && (
              <OrangeButton
                bottom={30}
                onPress={() => {
                  setSliderwizardpassed(true);
                }}
                title={"Continue"}
                nextarrow
                width={"84%"}
              />
            )}

          {questions &&
            questions[currentquestionno]?.type === "slider" &&
            sliderwizardpassed && (
              <OrangeButton
                bottom={30}
                onPress={async () => {
                  await answer(healthrater);
                }}
                title={"Confirm score"}
                width={"84%"}
              />
            )}
        </View>
      )}

      {questions && (
        <Text style={[styles.title4, { paddingBottom: insets.bottom }]}>
          {questions[currentquestionno]?.questionnaireId == 3 &&
            "© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v2.1"}
          {questions[currentquestionno]?.questionnaireId == 2 &&
            "© Bluebelle Wound Healing Questionnaire © University of Bristol 2017. All Rights Reserved. The authors have asserted their moral rights. Oxford University Innovation Limited is exclusively licensed to grant permissions to use the Bluebelle WHQ."}
          {questions[currentquestionno]?.questionnaireId == 4 &&
            "© Oxford University Innovation Limited, 1998. All rights reserved. Oxford Knee Score – English for the United Kingdom"}
          {questions[currentquestionno]?.questionnaireId == 5 &&
            "© Oxford University Innovation Limited, 1998. All rights reserved. Oxford Hip Score – English for the United Kingdom"}
        </Text>
      )}
    </View>
  );
};

export default ActivityQA;

const styles = StyleSheet.create({
  linearGradient: {
    height: 330,
    paddingLeft: 46,
    paddingRight: 46,
    borderRadius: 20,
  },
  buttonText: {
    fontSize: 18,
    fontFamily: "Gill Sans",
    textAlign: "center",
    margin: 10,
    color: "#ffffff",
  },

  bgimage: {
    flex: 1,
    resizeMode: "cover",
    width: "100%",
    height: "100%",
  },

  postoplogo: {
    marginTop: 32,

    width: 108,
    height: 37,
  },

  draghand: {
    position: "absolute",
    left: 0,

    top: 70,
    left: 19,
    zIndex: 200,
    width: 54,
    height: 62,
  },
  healthchart: {
    width: 79,
    height: 202,
    left: 122,
  },
  exitbutton: {
    width: 82,
    height: 37,
  },
  backbutton: {
    width: 96,
    height: 37,
  },

  container: {
    flex: 1,
    backgroundColor: "#E5E5F4",
  },
  title2: {
    fontSize: 20,
    lineHeight: 30,
    fontFamily: "MontserratMedium",
    color: "#FFFFFF",

    marginTop: 8.68,
    marginBottom: 16.8,
    marginLeft: 18.4,
    marginRight: 18.4,
  },
  title2a: {
    marginTop: 4,
  },
  title1: {
    fontSize: 16,
    fontFamily: "Lato",
    color: "#FFFFFF",
    marginTop: 14.7,
    marginLeft: 18.4,
  },
  title3: {
    fontSize: 18,
    fontFamily: "Lato",
    color: "#979FB0",
    marginTop: 8,
    paddingLeft: 11,
  },
  title4: {
    fontSize: 9,
    lineHeight: 10.8,
    fontFamily: "Lato",
    color: "#2A78F1",

    paddingHorizontal: 33,
    backgroundColor: "#ffffff",
    textAlign: "center",
    paddingBottom: 7,
    paddingTop: 7,
  },
  title5: {
    fontSize: 16,
    fontFamily: "Lato",
    color: "#97ECFF",
    marginTop: 14.7,
    marginLeft: 18.4,
  },
  title6: {
    fontSize: 16,
    fontFamily: "Lato",
    color: "#97ECFF",
    marginTop: 6,
    marginLeft: 18.4,
  },
  progressbar: {
    marginLeft: 11,
    marginRight: 11,
    height: 4,
    marginTop: 14,
    borderRadius: 10,
    backgroundColor: "#E5E5F4",
  },
  progressbarinside: {
    height: 4,
    borderRadius: 10,
    width: "4%",
    backgroundColor: "blue",
  },
  questionbutton: {
    alignItems: "flex-start",
    borderRadius: 58,
    backgroundColor: "#FFFFFF",
    borderColor: "#FE5935",
    borderWidth: 1,
    alignSelf: "center",
    alignContent: "center",
    flex: 1,
    minHeight: 20,
    paddingLeft: 29,
    paddingRight: 20,
    paddingVertical: 14,

    marginTop: 15,
  },
});
