export interface SimpleResult {
  message: string;
  result: boolean;
}
export type Result<T> = SimpleResult & T;

export interface Question {
  question: string;
  questionnaireId: number;
  id: number;
  type: string;
  answers?: Array<[string, number]>;
  showonlyquestion: boolean;
  showonlyanswer: boolean;
  dontshowforquestion: number | null;
  dontshowforanswer: number | null;
}

export type QuestionList = Question[];

export async function fetchQuestions(
  linkcode: string,
  linkyear: string
): Promise<Result<{ questions: QuestionList }>> {
  const response = await fetch(
    `${global.apiurl}/api/mobile/v1/patients/linkform/questions`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        link_code: linkcode,
        birth_year: linkyear,
      }),
    }
  );

  return await response.json();
}
